<template>
  <div class="dizme_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a href="#"
            ><img :src="`img/logo/${dark ? 'dark' : 'logo'}.png`" alt=""
          /></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><a href="#home">{{ $t('menu_home') }}</a></li>
            <li><a href="#about">{{ $t('menu_about') }}</a></li>
            <li><a href="#portfolio">{{ $t('menu_portfolio') }}</a></li>
            <li><a href="#service">{{ $t('menu_service') }}</a></li>
            <li><a href="#contact">{{ $t('menu_contact') }}</a></li>
            <li class="download_cv">
              <a :href="$t('cv_link')" target="_blank"><span>{{ $t('menu_download_cv') }}</span></a>
            </li>
          </ul>
            <div class="language-switcher">
                <img
                    src="img/flags/flag_pl.png"
                    alt="Poland"
                    class="flag"
                    @click="changeLanguage('pl')"
                    :class="{ active: selectedLanguage === 'pl' }"
                />

                <img
                    src="img/flags/flag_en.png"
                    alt="English"
                    class="flag"
                    @click="changeLanguage('en')"
                    :class="{ active: selectedLanguage === 'en' }"
                />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  props: {
    dark: { type: Boolean },
  },
    data() {
        return {
            selectedLanguage: this.$i18n.locale, // Get current locale
        };
    },
    methods: {
        changeLanguage(locale) {
            // Change the language in vue-i18n
            this.$i18n.locale = locale;

            // Update the selected language state
            this.selectedLanguage = locale;

            // Persist the selected language to localStorage
            localStorage.setItem('locale', locale);
        },
    },
};
</script>
<style scoped>
.language-switcher {
    display: flex;
    gap: 20px;
    position: absolute;
    top: 25px;
    right: 0px;
}

.flag {
    width: 25px;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.flag:hover {
    opacity: 0.7;
}

.flag.active {
    border: 1px solid #23a6f7;
    border-radius: 50%;
}
.menu{
    margin-right: 50px;
}
</style>
