import "swiper/css";
// import "swiper/css/pagination";
import { createApp } from "vue";
import App from "./App.vue";
import { i18n } from './i18n';
import router from "./router";
import "/public/css/custom.css";
import "/public/css/dark.css";
import "/public/css/plugins.css";
import "/public/css/style.css";



const app = createApp(App);
app.use(router);
app.use(i18n);
app.mount('#app');

