<template>
  <div class="dizme_tm_mobile_menu">
    <div class="mobile_menu_inner">
      <div class="mobile_in">
        <div class="logo">
          <a href="#"
            ><img :src="`img/logo/${dark ? 'dark' : 'logo'}.png`" alt=""
          /></a>
        </div>
          <div class="language-switcher">
              <img
                  src="img/flags/flag_pl.png"
                  alt="Poland"
                  class="flag"
                  @click="changeLanguage('pl')"
                  :class="{ active: selectedLanguage === 'pl' }"
              />

              <img
                  src="img/flags/flag_en.png"
                  alt="English"
                  class="flag"
                  @click="changeLanguage('en')"
                  :class="{ active: selectedLanguage === 'en' }"
              />
          </div>
        <div class="trigger" @click="activeToggle()">
          <div
            class="hamburger hamburger--slider"
            :class="active ? 'is-active' : ''"
          >
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown" :style="{ display: active ? 'block' : 'none' }">
      <div class="dropdown_inner">
        <ul class="anchor_nav">
          <li class="current"><a href="#home">{{ $t('menu_home') }}</a></li>
          <li><a href="#about">{{ $t('menu_about') }}</a></li>
          <li><a href="#portfolio">{{ $t('menu_portfolio') }}</a></li>
          <li><a href="#service">{{ $t('menu_service') }}</a></li>
          <li><a href="#contact">{{ $t('menu_contact') }}</a></li>
          <li class="download_cv">
            <a :href="$t('cv_link')" target="_blank"><span>{{ $t('menu_download_cv') }}</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  data() {
    return {
      active: false,
        selectedLanguage: this.$i18n.locale, // Get current locale
    };
  },
  methods: {
    activeToggle() {
      this.active = !this.active;
    },
      changeLanguage(locale) {
          // Change the language in vue-i18n
          this.$i18n.locale = locale;

          // Update the selected language state
          this.selectedLanguage = locale;

          // Persist the selected language to localStorage
          localStorage.setItem('locale', locale);
      },
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
<style scoped>
.language-switcher {
    display: flex;
    gap: 20px;
    position: absolute;
    top: 25px;
    right: 100px;
}

.flag {
    width: 25px;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.flag:hover {
    opacity: 0.7;
}

.flag.active {
    border: 1px solid #23a6f7;
    border-radius: 50%;
}

</style>
