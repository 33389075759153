import { createI18n } from 'vue-i18n';

const savedLocale = localStorage.getItem('locale') || 'pl';

export const i18n = createI18n({
    locale: savedLocale,
    messages: {
        en: {
            my_name: 'Tetiana Iliushchenko',
            welcome_text: 'Hello, I am a seasoned creative designer with substantial experience in industrial packaging and advertising product design. Over the course of my career, I have had the opportunity to work across various industries, developing innovative packaging solutions that prioritize product safety while enhancing visual appeal, alongside designing advertising materials that effectively communicate the core identity of brands and products.',
            about_me: 'About Me',
            menu_home: 'Home',
            menu_about: 'About',
            menu_portfolio: 'Portfolio',
            menu_service: 'Service',
            menu_contact: 'Contact',
            menu_download_cv: 'Download CV',
            process_title_1: 'Core skills',
            process_body_1_1: 'Illustration and Custom Artwork',
            process_body_1_2: 'Digital and Print Layouts',
            process_body_1_3: '3D Mockups and Prototyping',
            process_title_2: 'Experienced in',
            process_body_2_1: 'Branding and Identity Design',
            process_body_2_2: 'Typography and Font Selection',
            process_body_2_3: 'Color Theory and Psychology',
            process_title_3: 'Familiar with',
            process_body_3_1: 'Print Production Knowledge',
            process_body_3_2: 'Photography and Image Editing',
            process_body_3_3: 'Packaging Regulations and Compliance',
            about_years_of: 'Years of',
            about_success: 'Success',
            about_total: 'Total',
            about_projects: 'Projects',
            about_title: 'I can implement any of your ideas in design',
            about_text: 'I am an accomplished designer with a specialization in labels, cardboard packaging, and packaging solutions for the food and cosmetic industries. With extensive experience collaborating with prepress specialists, I possess a deep understanding of the distinct requirements across various sectors. Additionally, I am well-equipped to develop promotional materials, including product presentations and web banners.',
            about_hire_me: 'Hire Me',
            portfolio_subtitle: 'Portfolio',
            portfolio_title: 'Some of my success projects',
            portfolio_text: 'Just a small part of the projects I have completed to demonstrate my skills and successful experience',
            portfolio_extra_link: 'More of my projects on',
            portfolio_all: 'All',
            portfolio_package: 'Package',
            portfolio_labels: 'Labels',
            portfolio_advert: 'Advertising design',
            portfolio_brand: 'Brand identity',
            portfolio_category_package: 'Package design',
            portfolio_category_label: 'Label design',
            portfolio_category_advert: 'Advertising design',
            portfolio_category_brand: 'Brand identity',
            portfolio_product_1_title: 'Grilled chicken stripes',
            portfolio_product_2_title: 'Hemp oil',
            portfolio_product_3_title: 'Oat cookies',
            portfolio_product_4_title: 'Set of sauces',
            portfolio_product_5_title: 'Dietary supplement',
            portfolio_product_6_title: 'Corporate product catalog',
            portfolio_product_7_title: 'Date with almond in chocolate',
            portfolio_product_8_title: 'Set of hygiene products',
            portfolio_product_9_title: 'Plantain chips',
            portfolio_product_10_title: 'Coffee sachet',
            portfolio_product_11_title: 'Сoals for hookah',
            portfolio_product_12_title: 'Beauty care kit',
            portfolio_product_13_title: 'Gem bisquit',
            portfolio_product_14_title: 'Smoked cheese snacks',
            portfolio_product_15_title: 'Puff corn',
            portfolio_product_16_title: 'Beauty care kit',
            portfolio_product_17_title: 'Puttu breakfast',
            services_more: 'more...',
            services_starts_from: 'Starts from',
            services_title: 'Services',
            services_subtitle: 'What do I offer to clients',
            services_intro: 'The world of design is very diverse, and in every type of industrial or advertising design I can help you. These are just some of the typical areas of my projects.',
            services_tile1_title: 'Package Design',
            services_tile1_body_1: 'Packaging is the most popular type of work I do, covering a wide range of materials and purposes. From cardboard boxes and plastic wrappers to souvenirs and promotional products, these are my most common and successful projects. I specialize in packaging for industrial goods, a variety of solutions for the confectionery industry, and tailored designs for the beauty industry.',
            services_tile1_body_ext_1: 'Packaging is the most popular type of work I do, covering a wide range of materials and purposes. From cardboard boxes and plastic wrappers to souvenirs and promotional products, these are my most common and successful projects. I specialize in packaging for industrial goods, a variety of solutions for the confectionery industry, and tailored designs for the beauty industry.',
            services_tile1_body_ext_2: 'Thanks to many years of experience in real polygraph production as a leading designer of a large printing house, I have practical skills about the specifics of packaging production, the specifics of using various types of structures, recommended materials and post-printing technologies. I am able to prepare not only files suitable for printing, but also files for selective varnishing, foil stamping, and relief embossing. Experience in production with multicolor printing machines allows me to create designs with a variety of special and additional inks (Pantone, Metallic, etc.) All this will be prepared in the form of a package of files, maximally prepared for printing, which reduces the cost of prepress services .',
            services_tile1_body_ext_3: 'I invite you to cooperate in creating the ideal packaging solution for your product!',
            services_tile2_title: 'Label Design',
            services_tile2_body_1: 'The label can rightfully be called the face of the product. A successful design that emphasizes the strengths of a product and also takes into account the target audience can decide the fate of its sales. Of course, most often this is a label for food products, drinks, and the confectionery industry. But a significant part of my projects are labels for industrial products.',
            services_tile2_body_ext_1: 'A label can rightfully be called the face of a product. A well-designed label that highlights a product\'s strengths and resonates with its target audience can significantly impact its sales. While I often design labels for food products, beverages, and the confectionery industry, a significant portion of my work also involves labels for industrial products. With years of experience in label design, I\'ve developed key principles that guide each project. The first step is always a thorough discussion with the client about the product\'s mission, vision, market positioning, strengths, and target audience. In my experience, the most successful projects result from close collaboration with the client and a willingness to explore new ideas.',
            services_tile2_body_ext_2: 'I would be delighted to offer my services in creating the perfect label for your product!',
            services_tile3_title: 'Logo Design',
            services_tile3_body_1: 'A great logo is super important for making your brand pop. It\'s like the face of your brand, making it easy for folks to remember and recognize. When your logo looks good, it gives off good vibes and makes people feel good about your brand. Plus, it helps your brand stand out from the crowd and shows that you\'re pro and trustworthy.',
            services_tile3_body_ext_1: 'A great logo is super important for making your brand pop. It\'s like the face of your brand, making it easy for folks to remember and recognize. When your logo looks good, it gives off good vibes and makes people feel good about your brand. Plus, it helps your brand stand out from the crowd and shows that you\'re pro and trustworthy. A snazzy logo sets the style for your brand and makes your marketing stuff more memorable. It\'s like a shortcut that tells people what your brand is all about. And if your logo is adaptable and works everywhere, from tiny business cards to huge billboards, that\'s a win. Over time, people start associating your logo with your brand, and that builds loyalty. So, investing in a stunning logo is a smart move for keeping your brand on top.',
            services_tile3_body_ext_2: 'Let\'s start this journey together!',
            services_tile4_title: 'Brand Identity Design',
            services_tile4_body_1: 'Having a killer brand identity is like having a superpower for your business. It\'s what makes you unforgettable in a sea of competitors. Picture it like the personality of your brand – the vibes, the look, everything that makes you, well, you. When your brand identity is on point, people remember you.',
            services_tile4_body_ext_1: 'Having a killer brand identity is like having a superpower for your business. It\'s what makes you unforgettable in a sea of competitors. Picture it like the personality of your brand – the vibes, the look, everything that makes you, well, you.',
            services_tile4_body_ext_2: 'When your brand identity is on point, people remember you. It\'s not just about a logo; it\'s the whole package – the colors, the fonts, the style. It\'s like a language your brand speaks, and it makes you stand out. Think of it as your brand\'s signature look that people recognize you from afar. Consistency is the name of the game. Whether it\'s your website, social media, or a product label, if it all looks and feels the same, you\'re building trust. It\'s like telling your customers, "Hey, we\'re the real deal, and we\'re here to stay."',
            services_tile4_body_ext_3: 'I will be happy to become a co-author of your successful start!',
            testimonials_title: 'Testimonials',
            testimonials_subtitle: 'What My Clients Say',
            testimonials_feedback_1_name: 'Alina Prax',
            testimonials_feedback_1_country: 'USA',
            testimonials_feedback_1_body: 'Tetiana is the real deal, she is a complete consumate professional. I feel so lucky to have found her and can\'t wait to work with her again in the future. If she\'s available, hire her, don\'t wait! She knows her stuff and is fast and receptive to feedback. Invaluable freelancer!',
            testimonials_feedback_2_name: 'Pat Phillips',
            testimonials_feedback_2_country: 'Canada',
            testimonials_feedback_2_body: 'Tetiana does perfect work. She is a treat to work with. My business us a better business because of her. 100% professional. 100% in every category. This lady is a true pro.',
            testimonials_feedback_3_name: 'Erin Scolari',
            testimonials_feedback_3_country: 'USA',
            testimonials_feedback_3_body: 'Tetiana was fantastic! Super talented, great at execution and open communication. Got our vision and nailed it, highly recommend.',
            contact_title: 'Contact Me',
            contact_subtitle: 'I\'ll be happy to answer your questions',
            contact_body: 'Please fill out the form on this section to contact with me.',
            contact_address_1: 'Address',
            contact_address_2: 'Piaseczno, Poland',
            contact_email: 'Email',
            contact_form_required_fields: 'Please Fill Required Fields',
            contact_form_your_email: 'Your Email',
            contact_form_subject: 'Subject',
            contact_form_message: 'Write your message here',
            contact_form_captcha: 'This site is protected by reCAPTCHA and the Google',
            contact_form_privacy: 'Privacy Policy',
            contact_form_and: 'and',
            contact_form_tems: 'Terms of Service',
            contact_form_apply: 'apply',
            contact_form_submit: 'Submit Now',
            local_storage_warning: 'This website uses LocalStorage to save your language preferences for a better user experience.',
            cv_link: 'img/cv/Tetiana_Iliushchenko_designer_cv_eng.pdf',
        },
        pl: {
            my_name: 'Tetiana Iliushchenko',
            welcome_text: 'Witam, jestem doświadczonym projektantem kreatywnym z dużym doświadczeniem w projektowaniu opakowań przemysłowych oraz produktów reklamowych. W trakcie mojej kariery miałam okazję pracować w różnych branżach, tworząc innowacyjne rozwiązania opakowaniowe, które priorytetowo traktują bezpieczeństwo produktów, jednocześnie zwiększając ich atrakcyjność wizualną. Projektuję również materiały reklamowe, które skutecznie komunikują główną tożsamość marek i produktów.',
            about_me: 'O mnie',
            menu_home: 'Strona główna',
            menu_about: 'O mnie',
            menu_portfolio: 'Portfolio',
            menu_service: 'Usługi',
            menu_contact: 'Kontakt',
            menu_download_cv: 'Pobierz CV',
            process_title_1: 'Kluczowe umiejętności',
            process_body_1_1: 'Ilustracja i sztuka na zamówienie',
            process_body_1_2: 'Układy cyfrowe i drukowane',
            process_body_1_3: 'Makiety 3D i prototypowanie',
            process_title_2: 'Doświadczenie w',
            process_body_2_1: 'Branding i projektowanie tożsamości',
            process_body_2_2: 'Typografia i wybór czcionek',
            process_body_2_3: 'Teoria koloru i psychologia',
            process_title_3: 'Znajomość',
            process_body_3_1: 'Wiedza o produkcji drukowanej',
            process_body_3_2: 'Fotografia i edycja zdjęć',
            process_body_3_3: 'Przepisy i normy opakowaniowe',
            about_years_of: 'Lata',
            about_success: 'Sukcesy',
            about_total: 'Łącznie',
            about_projects: 'Projekty',
            about_title: 'Mogę zrealizować każdy Twój pomysł w zakresie projektowania',
            about_text: 'Jestem doświadczonym projektantem specjalizującym się w etykietach, opakowaniach kartonowych i rozwiązaniach opakowaniowych dla przemysłu spożywczego i kosmetycznego. Dzięki bogatemu doświadczeniu we współpracy z specjalistami ds. przygotowania do druku, posiadam głęboką wiedzę na temat specyficznych wymagań w różnych branżach. Dodatkowo, mam szerokie umiejętności w opracowywaniu materiałów promocyjnych, w tym prezentacji produktów i banerów internetowych.',
            about_hire_me: 'Zatrudnij mnie',
            portfolio_subtitle: 'Portfolio',
            portfolio_title: 'Niektóre z moich udanych projektów',
            portfolio_text: 'Tylko mała część projektów, które ukończyłam, aby pokazać moje umiejętności i udane doświadczenia',
            portfolio_extra_link: 'Więcej moich projektów na',
            portfolio_all: 'Wszystkie',
            portfolio_package: 'Opakowania',
            portfolio_labels: 'Etykiety',
            portfolio_advert: 'Projekt reklamowy',
            portfolio_brand: 'Tożsamość marki',
            portfolio_category_package: 'Projekt opakowania',
            portfolio_category_label: 'Projekt etykiety',
            portfolio_category_advert: 'Projekt reklamowy',
            portfolio_category_brand: 'Tożsamość marki',
            portfolio_product_1_title: 'Grillowane paski kurczaka',
            portfolio_product_2_title: 'Olej konopny',
            portfolio_product_3_title: 'Ciasteczka owsiane',
            portfolio_product_4_title: 'Zestaw sosów',
            portfolio_product_5_title: 'Suplement diety',
            portfolio_product_6_title: 'Katalog produktów firmowych',
            portfolio_product_7_title: 'Daktyl z migdałem w czekoladzie',
            portfolio_product_8_title: 'Zestaw produktów higienicznych',
            portfolio_product_9_title: 'Chipsy z babki lancetowatej',
            portfolio_product_10_title: 'Saszetka kawy',
            portfolio_product_11_title: 'Węgielki do shishy',
            portfolio_product_12_title: 'Zestaw do pielęgnacji urody',
            portfolio_product_13_title: 'Ciastka z galaretką',
            portfolio_product_14_title: 'Przekąski serowe wędzone',
            portfolio_product_15_title: 'Chrupki kukurydziane',
            portfolio_product_16_title: 'Zestaw do pielęgnacji urody',
            portfolio_product_17_title: 'Śniadanie Puttu',
            services_more: 'więcej...',
            services_starts_from: 'Cena od',
            services_title: 'Usługi',
            services_subtitle: 'Co oferuję klientom',
            services_intro: 'Świat projektowania jest bardzo różnorodny, a w każdym rodzaju projektowania przemysłowego lub reklamowego mogę Ci pomóc. To tylko niektóre z typowych obszarów moich projektów.',
            services_tile1_title: 'Projekt opakowania',
            services_tile1_body_1: 'Opakowanie to najpopularniejszy typ prac, które wykonuję, obejmujący szeroką gamę materiałów i celów. Od pudeł kartonowych i plastikowych opakowań po gadżety i produkty promocyjne – to moje najczęstsze i najbardziej udane projekty. Specjalizuję się w opakowaniach dla towarów przemysłowych, różnorodnych rozwiązaniach dla przemysłu cukierniczego oraz dopasowanych projektach dla branży kosmetycznej.',
            services_tile1_body_ext_1: 'Opakowanie to najpopularniejszy typ prac, które wykonuję, obejmujący szeroką gamę materiałów i celów. Od pudeł kartonowych i plastikowych opakowań po gadżety i produkty promocyjne – to moje najczęstsze i najbardziej udane projekty. Specjalizuję się w opakowaniach dla towarów przemysłowych, różnorodnych rozwiązaniach dla przemysłu cukierniczego oraz dopasowanych projektach dla branży kosmetycznej.',
            services_tile1_body_ext_2: 'Dzięki wieloletniemu doświadczeniu w realnej produkcji poligraficznej jako główny projektant dużej drukarni, posiadam praktyczne umiejętności dotyczące specyfiki produkcji opakowań, stosowania różnych struktur, rekomendowanych materiałów i technologii uszlachetniania druku. Potrafię przygotować nie tylko pliki gotowe do druku, ale także pliki do selektywnego lakierowania, tłoczenia folią i wytłaczania reliefowego. Doświadczenie w produkcji na maszynach wielokolorowych pozwala mi tworzyć projekty z różnorodnymi specjalnymi farbami (Pantone, Metallic, itp.). Wszystko to przygotowuję w formie pakietu plików maksymalnie gotowych do druku, co obniża koszty przygotowania do druku.',
            services_tile1_body_ext_3: 'Zapraszam do współpracy przy tworzeniu idealnego rozwiązania opakowaniowego dla Twojego produktu!',
            services_tile2_title: 'Projekt etykiety',
            services_tile2_body_1: 'Etykieta może słusznie być nazywana twarzą produktu. Udany projekt, który podkreśla mocne strony produktu i uwzględnia grupę docelową, może decydować o losach jego sprzedaży. Oczywiście, najczęściej projektuję etykiety dla produktów spożywczych, napojów oraz przemysłu cukierniczego, ale znaczną część moich projektów stanowią również etykiety dla produktów przemysłowych.',
            services_tile2_body_ext_1: 'Etykieta może słusznie być nazywana twarzą produktu. Dobrze zaprojektowana etykieta, która podkreśla mocne strony produktu i trafia do grupy docelowej, może znacznie wpłynąć na jego sprzedaż. Choć najczęściej projektuję etykiety dla produktów spożywczych, napojów i przemysłu cukierniczego, znaczną część mojej pracy stanowią również etykiety dla produktów przemysłowych. Z wieloletnim doświadczeniem w projektowaniu etykiet, wypracowałam kluczowe zasady, które kierują każdym projektem. Pierwszym krokiem zawsze jest szczegółowa rozmowa z klientem na temat misji, wizji, pozycjonowania rynkowego produktu, jego mocnych stron oraz grupy docelowej. Najbardziej udane projekty powstają z bliskiej współpracy z klientem i otwartości na nowe pomysły.',
            services_tile2_body_ext_2: 'Chętnie zaoferuję swoje usługi w tworzeniu idealnej etykiety dla Twojego produktu!',
            services_tile3_title: 'Projekt logo',
            services_tile3_body_1: 'Świetne logo jest super ważne, aby Twoja marka się wyróżniała. To jak twarz Twojej marki, sprawiająca, że jest łatwa do zapamiętania i rozpoznania. Kiedy logo wygląda dobrze, daje dobre wibracje i sprawia, że ludzie czują się dobrze w związku z Twoją marką. Dodatkowo, pomaga wyróżnić się na tle konkurencji i pokazuje, że jesteś profesjonalny i godny zaufania.',
            services_tile3_body_ext_1: 'Świetne logo jest super ważne, aby Twoja marka się wyróżniała. To jak twarz Twojej marki, sprawiająca, że jest łatwa do zapamiętania i rozpoznania. Kiedy logo wygląda dobrze, daje dobre wibracje i sprawia, że ludzie czują się dobrze w związku z Twoją marką. Dodatkowo, pomaga wyróżnić się na tle konkurencji i pokazuje, że jesteś profesjonalny i godny zaufania. Stylowe logo wyznacza styl Twojej marki i sprawia, że materiały marketingowe są bardziej zapadające w pamięć. To jak skrót, który mówi ludziom, czym jest Twoja marka. A jeśli logo jest elastyczne i dobrze wygląda wszędzie, od małych wizytówek po wielkie bilbordy, to jest to wygrana.',
            services_tile3_body_ext_2: 'Zacznijmy tę podróż razem!',
            services_tile4_title: 'Projekt tożsamości marki',
            services_tile4_body_1: 'Mocna tożsamość marki to jak supermoc dla Twojego biznesu. To, co sprawia, że jesteś niezapomniany w morzu konkurencji. Wyobraź sobie to jako osobowość Twojej marki – klimat, wygląd, wszystko, co Cię definiuje. Kiedy Twoja tożsamość marki jest na miejscu, ludzie Cię zapamiętują.',
            services_tile4_body_ext_1: 'Mocna tożsamość marki to jak supermoc dla Twojego biznesu. To, co sprawia, że jesteś niezapomniany w morzu konkurencji. Wyobraź sobie to jako osobowość Twojej marki – klimat, wygląd, wszystko, co Cię definiuje.',
            services_tile4_body_ext_2: 'Kiedy Twoja tożsamość marki jest na miejscu, ludzie Cię zapamiętują. To nie tylko logo; to cały pakiet – kolory, czcionki, styl. To jak język, który Twoja marka mówi, i sprawia, że się wyróżniasz. Pomyśl o tym jak o charakterystycznym wyglądzie, po którym ludzie Cię rozpoznają z daleka. Spójność to klucz. Niezależnie od tego, czy to Twoja strona internetowa, media społecznościowe, czy etykieta produktu, jeśli wszystko wygląda i czuje się tak samo, budujesz zaufanie. To jak mówienie swoim klientom: "Hej, jesteśmy prawdziwi i zostajemy na dłużej."',
            services_tile4_body_ext_3: 'Chętnie zostanę współautorką Twojego udanego startu!',
            testimonials_title: 'Opinie',
            testimonials_subtitle: 'Co mówią moi klienci',
            testimonials_feedback_1_name: 'Alina Prax',
            testimonials_feedback_1_country: 'USA',
            testimonials_feedback_1_body: 'Tetiana to prawdziwy profesjonalista. Mam ogromne szczęście, że ją znalazłam i nie mogę się doczekać kolejnej współpracy. Jeśli jest dostępna, zatrudnij ją bez wahania! Zna się na rzeczy, działa szybko i jest otwarta na feedback. Bezcenny freelancer!',
            testimonials_feedback_2_name: 'Pat Phillips',
            testimonials_feedback_2_country: 'Kanada',
            testimonials_feedback_2_body: 'Tetiana wykonuje perfekcyjną pracę. Praca z nią to przyjemność. Moja firma jest lepsza dzięki niej. 100% profesjonalizmu w każdej kategorii. Ta kobieta to prawdziwy profesjonalista.',
            testimonials_feedback_3_name: 'Erin Scolari',
            testimonials_feedback_3_country: 'USA',
            testimonials_feedback_3_body: 'Tetiana była fantastyczna! Niezwykle utalentowana, świetna w realizacji i otwarta na komunikację. Doskonale zrozumiała naszą wizję i ją zrealizowała. Gorąco polecam.',
            contact_title: 'Skontaktuj się ze mną',
            contact_subtitle: 'Chętnie odpowiem na Twoje pytania',
            contact_body: 'Proszę wypełnić formularz w tej sekcji, aby się ze mną skontaktować.',
            contact_address_1: 'Adres',
            contact_address_2: 'Piaseczno, Polska',
            contact_email: 'Email',
            contact_form_required_fields: 'Proszę wypełnić wymagane pola',
            contact_form_your_email: 'Twój email',
            contact_form_subject: 'Temat',
            contact_form_message: 'Napisz swoją wiadomość tutaj',
            contact_form_captcha: 'Ta strona jest chroniona przez reCAPTCHA i Google',
            contact_form_privacy: 'Polityka prywatności',
            contact_form_and: 'i',
            contact_form_tems: 'Warunki korzystania',
            contact_form_apply: 'mają zastosowanie',
            contact_form_submit: 'Wyślij teraz',
            local_storage_warning: 'Ta strona internetowa używa LocalStorage do zapisywania preferencji językowych w celu poprawy komfortu użytkowania.',
            cv_link: 'img/cv/Tetiana_Iliushchenko_designer_cv_pl.pdf',
        }

    },
});
