<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 1}.jpg`" alt="" />
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="26"></VueJsCounter>
                  </h3>
                  <span class="name">{{ $t('about_years_of') }}<br />{{ $t('about_success') }}</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">>
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="800"></VueJsCounter>
                  </h3>
                  <span class="name">{{ $t('about_total') }}<br />{{ $t('about_projects') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <h3>{{ $t('about_title') }}</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                  {{ $t('about_text') }}
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>{{ $t('about_hire_me') }}</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
